/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {
    arrayOf, string, shape, number, func,
} from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Product from '../../../GraphqlComponents/GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlSimpleProduct/GraphqlSimpleProduct';
import { trackEvent as track } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import CTA from '../../Buttons/CTA';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: theme.palette.tiers?.container?.maxWidth?.downSmall,
            margin: '20px auto',
            padding: '30px 2%',
        },
        [theme.breakpoints.up('sm')]: {
            padding: '2% 1%', // reduced padding by 1% on right and left to accomodate 6 items in a row
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: theme.palette.tiers?.container?.maxWidth?.downMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.palette.tiers?.container?.maxWidth?.upMedium,
            margin: '0 auto',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.palette.tiers?.container?.maxWidth?.upLarge,
            margin: '0 auto',
        },
    },
    product: {
        width: '300px',
    },
    hideAvailabilityMessaging: {
        '& p[name="deliveryOption"]': {
            display: 'none',
        },
    },
    mainContainer: {
        marginTop: '0',
        backgroundColor: theme.palette.tiers?.container?.backgroundColor,
        [theme.breakpoints.down(900)]: {
            width: '100%',
        },
        justifyContent: 'center',
    },
    titleWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        margin: '0',
        borderBottom: 'solid 1px #2f2f2f',
        alignItems: 'center',
    },
    title: {
        width: '100%',
        color: theme.palette.cta1,
        flex: 1,
        '& h1,h2,h3,h4,h5,p': {
            textAlign: 'left',
            textTransform: 'uppercase',
            fontSize: '1.2em',
            display: 'block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            margin: '0',
            padding: '0',
            [theme.breakpoints.down(415)]: {
                fontSize: '1em',
            },
        },
        '& > a': {
            display: 'block',
            fontSize: '.8em',
        },
    },
    titleWrapperSolitary: {
        width: '100%',
        fontWeight: '400',
        margin: '10px 0px',
    },
    titleSolitary: {
        textAlign: 'center',
        '& h1,h2,h3,h4,h5,p': {
            color: theme.palette.cta1,
            flex: 1,
            margin: '0 auto',
            padding: '0 .5em',
            textTransform: 'uppercase',
            fontSize: '1.4em',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            display: 'inline-block',
            position: 'relative',
            background: '#fff',
            fontWeight: '400',
        },

    },
    viewAll: {
        alignSelf: 'end',
        textDecoration: 'none',
        color: theme.palette.cta1,
        fontSize: '1em',
        position: 'relative',
        top: '.3em',
        cursor: 'pointer',
    },
    tierTitle: {
        position: 'absolute',
        bottom: 5,
        right: 0,
        left: 0,
        fontSize: '1em',
        fontWeight: 400,
        paddingLeft: 20,
        backgroundColor: theme.palette.tiers?.title.backgroundColor,
        textAlign: theme.palette.tiers?.title.upSmall.textAlign,
        lineHeight: theme.palette.tiers?.title.lineHeight,
        color: theme.palette.tiers?.title.color,
        [theme.breakpoints.down('sm')]: {
            lineHeight: 1.9,
            fontSize: 14,
            padding: 0,
            textAlign: theme.palette.tiers?.title.downSmall.textAlign,
        },
    },

    insideCard: {
        margin: 5,
        position: 'relative',
    },
    insideCardLink: {
        width: '100%',
    },
    reponsiveImage: theme.palette.tiers?.image,
    card: {
        backgroundColor: theme.palette.tiers?.card.backgroundColor,
        '& a p': {
            fontWeight: '400 !important',
            fontSize: '0.938em',
        },
        '& a p div': {
            fontWeight: '700 !important',
            fontSize: '1em',
        },
        '& a .MuiGrid-item:first-child p span': {
            fontWeight: '400',
        },
        [theme.breakpoints.down(900)]: {
            padding: '1rem 0.5rem !important', // overwrite the css
        },
    },
    titleBackgroundContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        margin: '0',
        padding: '10px 8px',
        alignItems: 'center',
        '& p': {
            margin: '0',
        },
        '& $title': {
            width: '100%',
            color: theme.palette.cms?.black || '#333',
            flex: 1,
            fontWeight: 600,
            '& h1,h2,h3,h4,h5,p': {
                textAlign: 'left',
                textTransform: 'capitalize',
                fontSize: '1.2em',
                display: 'block',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                margin: '0',
                padding: '0',
            },
            '& > a': {
                display: 'block',
                fontSize: '.8em',
            },
        },
    },
    viewAllShowOnBackground: {
        alignSelf: 'end',
        textDecoration: 'none',
        color: theme.palette.cms?.black || '#333',
        fontSize: '1em',
        position: 'relative',
        top: '.3em',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },

    },
    viewButton: {
        padding: '14px 30px 14px',
        zIndex: 1,
        [theme.breakpoints.down(415)]: {
            padding: '14px 2px',
            '& button': {
                padding: '8px 5px 9px',
            },
        },
    },
}));
const SubCollectionStandard = ({
    trackEvent, trackData, category,
    data,
}) => {
    const {
        heading = '', collectionLink = '',
        hideAvailabilityMessage = false,
        backgroundImage = '',
        isShowBackground = false,
        products = [],
        widthStyle = '',
        fontWeight = '',
    } = data || {};

    const brandId = data?.brand?.code;
    const classes = useStyles();

    const textColor = isShowBackground ? classes.departmentCopycolor : classes.title;
    const setClassBasedShowBackground = isShowBackground ? classes.titleBackgroundContainer : classes.titleWrapper;
    const viewAllTextOnShowBackground = isShowBackground ? classes.viewAllShowOnBackground : classes.viewAll;

    const getCard = (brand, tier, productCount, isLast = false) => {
        const {
            name = '', partNumber = '', seo = '', image = '', skuPriceRange = '',
            availability = '', prices = '', isPassportEligible = '', productSkus = [],
        } = tier;
        return (
            <Grid className={classes?.card} key={tier?.name} item lg={(productCount < 5) ? 3 : 2} md={3} sm={4} xs={isLast ? 12 : 6}>
                <Product
                    partNumber={partNumber}
                    name={name}
                    prices={prices}
                    image={image}
                    url={seo.url}
                    skuPriceRange={skuPriceRange}
                    availability={availability}
                    fromCategoryPage={typeof categoryData === 'object'}
                    categoryName={category?.name || ''}
                    categoryId={category?.id || ''}
                    track
                    isPassportEligible={isPassportEligible}
                    className={classes.product}
                    trackEvent={trackEvent}
                    trackData={trackData}
                    showSimpleProductRedesignAbTest={false}
                    productBrand={brandId}
                    isCategoryPage
                    productSkus={productSkus}
                />
            </Grid>
        );
    };

    let backgroundContainerImage = {};
    let backgroundContainerColor = {};
    let setMainSubContainer = {};
    let linkTextColor = {};

    // set backgroundimage from CMS
    if (isShowBackground) {
        backgroundContainerImage = {
            backgroundImage: `url(${backgroundImage}?auto=webp)`,
        };
        backgroundContainerColor = {
            backgroundColor: data?.backgroundColor,
            color: data?.textColor,
            fontWeight,
        };
        setMainSubContainer = {
            maxWidth: widthStyle,
            padding: '1% 0',
        };
        linkTextColor = {
            color: data?.textColor,
        };
    }

    return (
        <Grid container style={setMainSubContainer} direction="row" justify="center" alignItems="center" className={`${classes.container}`}>
            { /* if there is no link (e.g. View All) then render a center-justified header with decorative line */}
            {heading && !collectionLink ? (
                <div className={classes.titleWrapperSolitary}>
                    <ReactMarkdown className={classes.titleSolitary} source={heading} />
                </div>
            ) : ''}
            { /* ...otherwise show the heading title and place a link to the right side */}
            {heading && collectionLink ? (
                <div
                    style={backgroundImage ? backgroundContainerImage : backgroundContainerColor}
                    className={setClassBasedShowBackground}
                >
                    <ReactMarkdown style={linkTextColor} className={textColor} source={heading} />

                    <div className={classes.viewButton}>
                        <CTA
                            href={collectionLink}
                            copy={data?.viewAllButton}
                            className={viewAllTextOnShowBackground}
                            fontColor={linkTextColor?.color}
                            tracking_event_category={trackData?.tracking_event_category}
                            tracking_event_action={trackData?.tracking_event_action}
                            tracking_event_label={trackData?.tracking_event_label}
                        />
                    </div>
                </div>
            ) : ''}

            <Grid className={`${classes.mainContainer} ${hideAvailabilityMessage && classes.hideAvailabilityMessaging}`} container spacing={4}>
                {
                    products.map((tier) => (
                        getCard(brandId, tier, products.length)
                    ))
                }
            </Grid>
        </Grid>
    );
};

SubCollectionStandard.propTypes = {
    data: shape({
        heading: string,
        collectionLink: string,
        products: arrayOf(
            shape({
                id: string.isRequired,
                name: string.isRequired,
                image: shape({
                    name: string.isRequired,
                    path: string.isRequired,
                    url: string.isRequired,
                }).isRequired,
                prices: arrayOf(
                    shape({
                        value: number.isRequired,
                    }),
                ).isRequired,
                skuPriceRange: shape({
                    sale: arrayOf(
                        shape({
                            value: number.isRequired,
                        }),
                    ),
                    retail: arrayOf(
                        shape({
                            value: number.isRequired,
                        }),
                    ),
                }).isRequired,
                seo: shape({
                    url: string.isRequired,
                }).isRequired,
            }),
        ).isRequired,
    }).isRequired,
    trackEvent: func,
    trackData: shape({
        tracking_event_action: string,
        tracking_event_category: string,
        tracking_event_label: string,
    }),
    category: shape({
        name: string,
        id: string,
    }),
};

SubCollectionStandard.defaultProps = {
    trackData: {},
    category: {},
    trackEvent: () => {},
};

const mapDispatchToProps = (dispatch) => ({
    trackEvent: bindActionCreators(track, dispatch),
});

export default connect(
    null,
    mapDispatchToProps,
)(SubCollectionStandard);
