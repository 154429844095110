/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    array, bool, number, shape, string,
} from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';
import { Grid } from '@material-ui/core';
import CTA from '../../../Buttons/CTA';
import { getPresentationFamily } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';

// strips "font-family:" and ";" from value
const parseFont = (font) => font?.match(/:\s(.*?);/, '')?.[1] || null;

const useStyles = makeStyles((theme) => ({
    eyeBrowBanner: {
        display: 'flex',
        fontSize: 16,
        '& p,h1,h2,h3,h4,h5,h6': {
            margin: 0,
            padding: 0,
        },
    },
    mainHeadingStyle: (styles) => ({
        fontFamily: parseFont(styles?.fontFamily),
        color: styles?.headingFontColor || '#000000',
        fontSize: styles?.fontSize || '2.2em',
        '@media screen and (min-width: 600px) and (max-width: 1024px)': {
            lineHeight: '1',
        },
        '& p,h1,h2,h3,h4,h5,h6': {
            margin: '5px 0px',
            fontSize: 'inherit',
            fontFamily: 'inherit',
        },
    }),
    adjustPaddingEyeBrow: {
        padding: '10px 25px 9px',
        marginRight: 16,
    },
    reminderText: {
        fontWeight: 600,
        textAlign: 'center',
    },
    eyeBrowCopy: {
        display: 'flex',
        alignItems: 'center',
    },
    viewButton: {
        padding: '14px 30px 14px',
        backgroundColor: theme.palette.cms?.white || '#ffffff',
        zIndex: 1,
        cursor: 'pointer',
    },
    viewButtonLink: {
        color: theme.palette.cms?.black || '#333',
        textDecoration: 'none',
        fontSize: 14,
        fontWeight: 600,
    },
    mainHeading: (styles) => ({
        zIndex: 1,
        padding: '0px 16px 9px 0px',
        backgroundColor: theme.palette.cms?.white || '#ffffff',
        fontFamily: parseFont(styles?.fontFamily),
        fontSize: styles?.fontSize || '2.2em',
        color: styles?.headingFontColor || '#000000',
    }),
    headingAlign: {
        textAlign: 'center',
    },
    withViewAllButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative',
        '&:before': {
            top: '50%',
            left: '0',
            right: '0',
            width: '100%',
            height: '1px',
            content: '""',
            zIndex: '1',
            position: 'absolute',
            background: theme.palette.cms?.horizentalLineBorder || '#dedede',
        },
    },
    mainBrandName: {
        fontSize: 13,
        textTransform: 'uppercase',
        color: theme.palette.cms?.brandNameColor || '#b4b4b4',
        margin: '8px 0px',
        '& span:after': {
            content: '"|"',
            margin: '0px 6px',
        },
        '& span:last-child:after': {
            content: '""',
        },
    },
}));

const  SubcollectionHeader = ({
    eventEyebrow,
    viewAllButton,
    headingCopy,
    headingFontFaimly,
    parentBrands,
    showEyeBrow,
    headingFontSize,
    headingFontColor,
}) => {
    let headingStyle = {};
    headingStyle = {
        fontFamily: headingFontFaimly,
        fontSize: `${headingFontSize}px`,
        fontColor: headingFontColor,
    };

    const classes = useStyles(headingStyle);
    const presentationFamily = useSelector(getPresentationFamily);

    return (
        <Grid contanier className={classes.subCollectionHeader}>
            <Grid item xs={12} className="subCollectionHeading">
                {/** EyeBrow Banner */}
                {showEyeBrow && (eventEyebrow?.copy_block || eventEyebrow?.reminder_text) && (
                    <div className={classes.eyeBrowBanner}>
                        <span className={classes.adjustPaddingEyeBrow} style={{ backgroundColor: eventEyebrow?.background_color?.color }}>
                            <ReactMarkdown className={classes.reminderText} source={eventEyebrow?.reminder_text} escapeHtml={false} />
                        </span>
                        <span className={classes.eyeBrowCopy}>
                            <ReactMarkdown source={eventEyebrow?.copy_block} escapeHtml={false} />
                        </span>
                    </div>
                )}
                {/** Main Heeading Section */}
                {viewAllButton?.link?.title && viewAllButton?.link?.href
                    ? (
                        <div className={classes.withViewAllButton}>
                            <div className={classes.mainHeading}>
                                {headingCopy}
                            </div>
                            <div className={classes.viewButton}>
                                <CTA
                                    href={viewAllButton?.link?.href}
                                    copy={viewAllButton?.link?.title}
                                    title={viewAllButton?.link?.title}
                                    tracking_event_category={viewAllButton?.tracking_event_category}
                                    tracking_event_action={viewAllButton?.tracking_event_action}
                                    tracking_event_label={viewAllButton?.tracking_event_label}
                                />
                            </div>

                        </div>
                    )
                    : headingCopy && (
                        <div>
                            <ReactMarkdown
                                className={presentationFamily === 'flower' ? `${classes.mainHeadingStyle} ${classes.headingAlign}` : classes.mainHeadingStyle}
                                source={headingCopy}
                                escapeHtml={false}
                            />
                        </div>
                    )}

                {parentBrands?.brand.length > 0 && (
                    <div className={classes.mainBrandName}>
                        {parentBrands?.brand?.map((brandName) => (
                            <span className={classes.brandNameStyle}>{brandName}</span>
                        ))}
                    </div>
                )}

            </Grid>
        </Grid>
    );
};

SubcollectionHeader.propTypes = {
    eventEyebrow: shape({
        copy_block: string,
        reminder_text: string,
        background_color: shape({
            color: string,
        }),
    }),
    viewAllButton: shape({
        href: string,
        title: string,

    }),
    headingCopy: string.isRequired,
    headingFontFaimly: string.isRequired,
    parentBrands: array.isRequired,
    showEyeBrow: bool.isRequired,
    headingFontSize: number.isRequired,
    headingFontColor: string,
};
SubcollectionHeader.defaultProps = {
    eventEyebrow: {},
    viewAllButton: {},
    headingFontColor: '',
};
export default (SubcollectionHeader);
